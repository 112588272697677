import { RightCircleOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";
import { graphql } from "gatsby";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { chunk, filter, slice } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import styled from "styled-components";

import CoverVideo from "@components/CoverVideo";
import Layout from "@components/Layout";
import LocalizedLink from "@components/LocalizedLink";
import LocationContext from "@components/LocationContext";
import SEO from "@components/SEO";
import Subheading from "@components/Subheading";
import { publicIdToLocal } from "@utils/cloudinary";

const NewsSectionCSS = styled.div`
  width: 70%;
  padding-bottom: 2rem;

  @media (max-width: 800px) {
    width: 85%;
  }

  margin: 0 auto;

  a {
    color: black;
  }

  .entry-title {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .entry-date {
    color: gray;
  }

  .entry-description {
    font-size: 1rem;
    color: #777;
  }

  text-align: center;

  #news-link {
    line-height: 4rem;
  }
`;

const { Paragraph } = Typography;

const IndexPageCSS = styled.div`
  video {
    object-fit: cover;
    height: 700px;
    width: 100%;
    filter: brightness(0.7);
  }
 

  .video-overlay {
    position: absolute;
    top: 350px;
    color: #fff;
    width: 100vw;
    word-wrap: break-word;

    text-align: left;
    @media (min-width: 1200px) {
      font-size: 4.5rem;
      transform: translate(15%,-45%);
    }

    @media (min-width: 992px) and (max-width: 1200px) {
      font-size: 3.5rem;
      transform: translate(15%,-45%);
    }

    @media (min-width: 768px) and (max-width: 992px) {
      font-size: 3rem;
      .slogan {
        margin-left:40px;
      }
    }

  @media (max-width: 768px) {
    font-size: 2rem;
    .slogan {
      margin-left:40px;
    }
  }
    word-break: keep-all;
  }

  section#intro-wrapper {
    padding-bottom: 100px;

    .ant-typography.subtitle {
      padding: 3rem 3rem 1.5rem 3rem;
      font-weight: 500;
      text-align: center;
      margin: auto;
      line-height: 2rem;

      @media (min-width: 1024px) {
        width: 70vw;
      }
    }

    .section-link {
      width: 70vw;
      margin: auto;
      text-align: center;

      a {
        color: #244a1e;
      }
    }
  }
`;

const ProductBannerCSS = styled.div`
  background-color: #c7d8c6;
  padding: 2rem 0;
  overflow: hidden;

  @media (max-width: 800px) {
    max-height: 550px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .product-fadein-active {
    animation: fadeIn 1.2s forwards;
  }

  div#product-banner-wrapper {
    opacity: 0;

    #product-text {
      max-width: 60%;

      @media (max-width: 800px) {
        max-width: 100%;
      }
    }

    @media (min-width: 1024px) {
      width: 70vw;
    }

    margin: 0 auto;

    display: flex;
    justify-content: space-evenly;

    @media (max-width: 800px) {
      display: block;
    }

    .ant-typography.subtitle {
      padding: 3rem 3rem 1rem 3rem;
      font-weight: 500;
      margin: auto;
      color: #244a1e;
      line-height: 2rem;

      @media (max-width: 800px) {
        font-size: ${props => (props.language ? "1rem" : "auto")};
        font-weight: ${props => (props.language ? "700" : "auto")};
      }

      @media (max-width: 600px) {
        padding: 0 0 0 1rem;
      }

      @media (min-width: 1280px) {
        width: 75%;
      }
    }

    div#product-img-wrapper {
      display: flex;
      justify-content: center;
    }

    > div {
      align-self: center;
      text-align: center;
    }

    .section-link {
      margin-top: 1rem;

      a {
        color: #244a1e;
        text-align: center;
      }

      @media (max-width: 600px) {
        padding-left: 1rem;
      }
    }

    img {
      margin: 0 auto;
      z-index: 0;

      max-height: 550px;
    }
  }
`;

const CSRBannerCSS = styled.div`
  padding-top: 2rem;
  padding-bottom: 50px;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .csr-fadein-active {
    animation: fadeIn 0.8s forwards;
  }

  div#csr-banner-wrapper {
    opacity: 0;
    padding: 5%;

    display: flex;
    justify-content: space-evenly;

    @media (max-width: 900px) {
      display: block;
    }

    .ant-typography.subtitle {
      word-break: keep-all;
      padding: 2rem 3rem 3rem 3rem;
      font-weight: 500;
      margin: auto;
      color: #244a1e;
      font-size: 2.5rem;

      @media (max-width: 1024px) {
        font-size: 1.5rem;
      }
    }

    .ant-typography.subtitle-2 {
      font-weight: 500;
      font-size: 1.25rem;
    }

    > div {
      align-self: center;
      text-align: center;

      padding: 1.5rem;
    }

    .section-link {
      margin-top: 1rem;

      a {
        color: #244a1e;
      }
    }

    img {
      margin: 0 auto;
      max-width: 70%;
      max-height: 600px;
    }

    @media (max-width: 900px) {
      img {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
`;

const gridStyle = gridCol => ({
  width: `${100 / gridCol}%`,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ArticleCards = ({ location, articles }) => {
  const isBrowser = typeof window !== "undefined";
  const { i18n } = useTranslation();
  const [cardGridCol, setCardGridGrid] = useState(2);

  useEffect(() => {
    if (isBrowser) {
      const updateHistoryColNum = () => {
        if (window.innerWidth < 1024) {
          setCardGridGrid(1);
        } else {
          setCardGridGrid(2);
        }
      };
      updateHistoryColNum()
      window.addEventListener("resize", updateHistoryColNum);
    }
  }, [cardGridCol]);

  return chunk(
    slice(
      filter(articles, { node: { fields: { langKey: i18n.language } } }),
      0,
      4
    ),
    cardGridCol
  ).map((row, index) => (
    <Card bordered="false" key={index}>
      {row.map((entry, unique) => {
        return (
          <Card.Grid style={gridStyle(cardGridCol)} bordered="false" key={unique}>
            <LocalizedLink
              language={i18n.language}
              to={entry.node.fields.slug}
              state={{ prevPath: location.pathname }}
            >
              <div className="entry-title">{entry.node.frontmatter.title}</div>
              <div className="entry-date">
                {moment(entry.node.frontmatter.date).format("YYYY.MM.DD")}
              </div>
              <div className="entry-description">
                {entry.node.frontmatter.description}
              </div>
            </LocalizedLink>
          </Card.Grid>
        );
      })}
    </Card>
  ));
};

const NewsSection = ({ t, i18n, data, location }) => {
  const {
    allMarkdownRemark: { edges: articles },
  } = data;

  return (
    <NewsSectionCSS>
      <Subheading text={t("news")} />
      <ArticleCards articles={articles} location={location} />
      <LocalizedLink
        id="news-link"
        to="/media#news"
        language={i18n.language}
        style={{ color: "#244a1e" }}
      >
        {t("about1")} {<RightCircleOutlined />}
      </LocalizedLink>
    </NewsSectionCSS>
  );
};

export const IndexPageTemplate = props => {
  const { t, i18n } = useTranslation();
  const [productFadeIn, setProductFadeIn] = useState("");
  const [csrFadeIn, setCsrFadeIn] = useState("");
  const { data, location, countryCode } = props;

  return (
    <IndexPageCSS language={i18n.language} t={t} i18n={i18n}>
      <div>
        <CoverVideo
          secure_url={publicIdToLocal["biel/biel-jumbotron-video_sg4j1c"]}
        />
        <div className="video-overlay">
          <div className="slogan">
            <div>{t("slogan1")}</div>
            <div className="slogan2">{t("slogan2")}</div>
          </div>
        </div>
      </div>
      <section id="intro-wrapper">
        <Paragraph className="subtitle">{t("intro")}</Paragraph>
        <div className="section-link">
          <LocalizedLink
            to="/about"
            language={i18n.language}
            activeStyle={{ color: "#244a1e" }}
          >
            {t("about1")} {<RightCircleOutlined />}
          </LocalizedLink>
        </div>
      </section>
      <Waypoint
        onEnter={() => {
          setProductFadeIn("product-fadein-active");
        }}
        topOffset="60%"
        bottomOffset="60%"
      >
        <ProductBannerCSS language={i18n.language}>
          <div id="product-banner-wrapper" className={productFadeIn}>
            <div id="product-text">
              <Paragraph className="subtitle">{t("productMain")}</Paragraph>
              <div className="section-link">
                <LocalizedLink to="/business#product" language={i18n.language}>
                  {t("product")} {<RightCircleOutlined />}
                </LocalizedLink>
              </div>
            </div>
            <div id="product-img-wrapper">
              <img
                src={publicIdToLocal["biel/glassyv3_uwj1xw"]}
              />
            </div>
          </div>
        </ProductBannerCSS>
      </Waypoint>
      <Waypoint
        onEnter={() => {
          setCsrFadeIn("csr-fadein-active");
        }}
        topOffset="60%%"
        bottomOffset="60%"
      >
        <CSRBannerCSS>
          <div id="csr-banner-wrapper" className={csrFadeIn}>
            <img
              src={publicIdToLocal["biel/donation_tiianc"]}
            />
            <div>
              <Paragraph className="subtitle">{t("csrTitle")}</Paragraph>
              <Paragraph className="subtitle-2">{t("csrContent")}</Paragraph>
              <div className="section-link">
                <LocalizedLink to="/csr" lanugae={i18n.language}>
                  {t("about1")} {<RightCircleOutlined />}
                </LocalizedLink>
              </div>
            </div>
          </div>
        </CSRBannerCSS>
      </Waypoint>
      <NewsSection data={data} t={t} i18n={i18n} location={location} />
    </IndexPageCSS>
  );
};

const IndexPage = ({ data, location: locations }) => {
  const { t, i18n } = useTranslation();

  return (
    <Layout location={locations} t={t} i18={i18n}>
      <SEO title={t("title")} description={t('description')} pathname={locations.pathname} />
      <LocationContext.Consumer>
        {props => (
          <IndexPageTemplate
            data={data}
            location={locations}
            {...props}
          />
        )}
      </LocationContext.Consumer>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {ns: {in: ["home", "footer"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "news-article" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            langKey
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }}
`;
