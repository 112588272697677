import React from 'react'

import poster from '@img/biel_poster.jpg'

const CoverVideo = ({ secure_url }) => {
  return (
    <video id="cover-video"
      poster={poster}
      autoPlay
      loop
      muted
      defaultmuted="true"
      playsInline
    >
      <source src={secure_url} type="video/mp4" />
      Video not supported
    </video>
  )
}

export default CoverVideo
